import clsx from 'clsx'
import { Formik } from 'formik'
import { IMaskInput } from 'react-imask'
import { Button } from 'components/Button'
import { Field, Form, Select } from 'components/Form'
import formStyles from 'components/Form/styles.module.scss'
import { Grid } from 'components/Grid'
import { Modal } from 'components/Modal'
import { Banking as IBanking } from 'types'
import { createScheme, required } from 'utils/schemas'
import styles from './styles.module.scss'

interface Props {
  banking: IBanking | null
  saving: boolean
  onSave: (banking: IBanking) => void
  onCancel: () => void
}

const Schema = createScheme({
  accountNumber: required,
  routingNumber: required,
  accountName: required,
  accountType: required,
  immediateOrigin: required,
  immediateOriginName: required,
  referenceCode: required,
  companyName: required,
  standardEntryClassCode: required,
  companyIdentification: required,
  companyEntryDescription: required,
})

function Banking({ banking, saving, onSave, onCancel }: Props) {
  const initialValue: IBanking = {
    accountNumber: banking?.accountNumber || '',
    routingNumber: banking?.routingNumber || '',
    accountName: banking?.accountName || '',
    accountType: banking?.accountType || 'checking',
    immediateOrigin: banking?.immediateOrigin || '',
    immediateOriginName: banking?.immediateOriginName || '',
    referenceCode: banking?.referenceCode || '',
    companyName: banking?.companyName || '',
    standardEntryClassCode: banking?.standardEntryClassCode || '',
    companyIdentification: banking?.companyIdentification || '',
    companyEntryDescription: banking?.companyEntryDescription || '',
    companyDiscretionaryData: banking?.companyDiscretionaryData || '',
  }

  return (
    <Modal title="NACHA Settings" onClose={onCancel} className={styles.modal}>
      <Formik
        initialValues={initialValue}
        validationSchema={Schema}
        onSubmit={onSave}
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={6}>
              <Field label="Account Name" name="accountName">
                {({ field, meta: { touched, error } }) => (
                  <IMaskInput
                    mask={/^[A-Za-z0-9\s]+$/}
                    autoComplete="off"
                    maxLength={23}
                    className={clsx(formStyles.input, {
                      [formStyles.errorField]: touched && error,
                    })}
                    {...field}
                  />
                )}
              </Field>
            </Grid.Item>
            <Grid.Item xs={6}>
              <Field label="Account Number" name="accountNumber">
                {({ field, meta: { touched, error } }) => (
                  <IMaskInput
                    mask={/^\d+$/}
                    autoComplete="off"
                    maxLength={17}
                    className={clsx(formStyles.input, {
                      [formStyles.errorField]: touched && error,
                    })}
                    {...field}
                  />
                )}
              </Field>
            </Grid.Item>
            <Grid.Item xs={6}>
              <Field label="Routing Number" name="routingNumber">
                {({ field, meta: { touched, error } }) => (
                  <IMaskInput
                    mask={/^\d+$/}
                    autoComplete="off"
                    maxLength={9}
                    className={clsx(formStyles.input, {
                      [formStyles.errorField]: touched && error,
                    })}
                    {...field}
                  />
                )}
              </Field>
            </Grid.Item>
            <Grid.Item xs={6}>
              <Select
                name="accountType"
                label="Type"
                options={[
                  { label: 'Checking', value: 'checking' },
                  { label: 'Savings', value: 'savings' },
                ]}
                portal
              />
            </Grid.Item>
            <Grid.Item xs={6}>
              <Field label="Immediate Origin" name="immediateOrigin">
                {({ field, meta: { touched, error } }) => (
                  <IMaskInput
                    mask={/^[A-Za-z0-9\s]+$/}
                    autoComplete="off"
                    maxLength={10}
                    className={clsx(formStyles.input, {
                      [formStyles.errorField]: touched && error,
                    })}
                    {...field}
                  />
                )}
              </Field>
            </Grid.Item>
            <Grid.Item xs={6}>
              <Field label="Immediate Origin Name" name="immediateOriginName">
                {({ field, meta: { touched, error } }) => (
                  <IMaskInput
                    mask={/^[A-Za-z0-9\s]+$/}
                    autoComplete="off"
                    maxLength={32}
                    className={clsx(formStyles.input, {
                      [formStyles.errorField]: touched && error,
                    })}
                    {...field}
                  />
                )}
              </Field>
            </Grid.Item>
            <Grid.Item xs={6}>
              <Field label="Reference Code" name="referenceCode">
                {({ field, meta: { touched, error } }) => (
                  <IMaskInput
                    mask={/^[A-Za-z0-9\s]+$/}
                    autoComplete="off"
                    maxLength={8}
                    className={clsx(formStyles.input, {
                      [formStyles.errorField]: touched && error,
                    })}
                    {...field}
                  />
                )}
              </Field>
            </Grid.Item>
            <Grid.Item xs={6}>
              <Field label="Company Name" name="companyName">
                {({ field, meta: { touched, error } }) => (
                  <IMaskInput
                    mask={/^[A-Za-z0-9\s]+$/}
                    autoComplete="off"
                    maxLength={16}
                    className={clsx(formStyles.input, {
                      [formStyles.errorField]: touched && error,
                    })}
                    {...field}
                  />
                )}
              </Field>
            </Grid.Item>
            <Grid.Item xs={6}>
              <Field
                label="Standard Entry Class Code"
                name="standardEntryClassCode"
              >
                {({ field, meta: { touched, error } }) => (
                  <IMaskInput
                    mask={/^[A-Za-z0-9\s]+$/}
                    autoComplete="off"
                    maxLength={3}
                    className={clsx(formStyles.input, {
                      [formStyles.errorField]: touched && error,
                    })}
                    {...field}
                  />
                )}
              </Field>
            </Grid.Item>
            <Grid.Item xs={6}>
              <Field
                label="Company Identification"
                name="companyIdentification"
              >
                {({ field, meta: { touched, error } }) => (
                  <IMaskInput
                    mask={/^[A-Za-z0-9\s]+$/}
                    autoComplete="off"
                    maxLength={10}
                    className={clsx(formStyles.input, {
                      [formStyles.errorField]: touched && error,
                    })}
                    {...field}
                  />
                )}
              </Field>
            </Grid.Item>
            <Grid.Item xs={6}>
              <Field
                label="Company Entry Description"
                name="companyEntryDescription"
              >
                {({ field, meta: { touched, error } }) => (
                  <IMaskInput
                    mask={/^[A-Za-z0-9\s]+$/}
                    autoComplete="off"
                    maxLength={10}
                    className={clsx(formStyles.input, {
                      [formStyles.errorField]: touched && error,
                    })}
                    {...field}
                  />
                )}
              </Field>
            </Grid.Item>
            <Grid.Item xs={6}>
              <Field
                label="Company Discretionary Data"
                name="companyDiscretionaryData"
              >
                {({ field, meta: { touched, error } }) => (
                  <IMaskInput
                    mask={/^[A-Za-z0-9\s]+$/}
                    autoComplete="off"
                    maxLength={10}
                    className={clsx(formStyles.input, {
                      [formStyles.errorField]: touched && error,
                    })}
                    {...field}
                  />
                )}
              </Field>
            </Grid.Item>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" loading={saving}>
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export default Banking
